<template>
  <div v-if="timeCard && timeCardActivities">

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="float-left">
        <h4 class="float-left mr-3" id="top">Activity Report Ending <small>{{ formattedDate(timeCard.week_start_date, 7) }}</small></h4>
        <h5 class="float-left"><b-badge :variant="determineStatus()">{{ timeCard.status }}</b-badge></h5>
      </div>
    </div>

    <div v-if="timeCard.rejection_note" class="alert alert-danger rejection-notes" role="alert">
        <h4>Rejection Note(s):</h4>
        <p>{{ timeCard.rejection_note }}</p>
    </div>

    <div class="card">
      <div class="rainbow-background"></div>
      <div class="table-responsive">
        <table class="card-table table">
          <thead>
          <tr>
            <th class="align-middle">Activity</th>
            <th class="align-middle">Region</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 1) }}</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 2) }}</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 3) }}</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 4) }}</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 5) }}</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 6) }}</th>
            <th class="align-middle">{{ formattedDate(timeCard.week_start_date, 7) }}</th>
            <th class="align-middle">Week</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="timeCardActivity in timeCardActivities"  :id="timeCardActivity.id">
            <tr style="border-top: double" v-bind:key="timeCardActivity.id">
              <td class="align-middle">
                {{ timeCardActivity.activity.title }}
              </td>
              <td class="align-middle">
                {{ regionName(timeCardActivity.region) }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_one }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_two }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_three }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_four }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_five }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_six }}
              </td>
              <td class="align-middle">
                {{ timeCardActivity.day_seven }}
              </td>
              <td class="align-middle">
                <b class="week-activity-total">{{ calculateWeekTotal(timeCardActivity) }}</b>
              </td>
            </tr>
            <tr v-bind:key="timeCardActivity.id + 1">
              <td align="left" colspan="10">
                <b>Audiences:</b> {{ printTopics(timeCardActivity) }}
              </td>
            </tr>
            <tr v-bind:key="timeCardActivity.id + 2">
              <td align="left" colspan="10">
                <b>Trainees:</b> {{ printTrainees(timeCardActivity) }}
              </td>
            </tr>
            <tr v-bind:key="timeCardActivity.id + 3">
              <td align="left" colspan="10">
                <b>Comment:</b> {{ printComment(timeCardActivity) }}
              </td>
            </tr>
          </template>
          <tr style="border-top: double">
            <td class="align-middle"><b>Total Days</b></td>
            <td />
            <td class="align-middle"><b>{{ sumDay('day_one') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_two') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_three') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_four') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_five') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_six') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_seven') }}</b></td>
            <td class="align-middle"><b>{{ sumDay('day_one') + sumDay('day_two') + sumDay('day_three') + sumDay('day_four') + sumDay('day_five') + sumDay('day_six') + sumDay('day_seven') }} Total Days</b></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="btn-group float-right">
          <router-link :to="{ name: 'TimeCardIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
          <button :disabled="unsubmitTimeout" v-if="timeCard.status === 'Submitted' || timeCard.status === 'Resubmitted'" class="btn btn-warning" @click="unsubmitTimeCard">Unsubmit</button>
          <router-link v-if="timeCard.status === 'In Progress' || timeCard.status === 'Rejected'" class="btn btn-primary" :to="{ name: 'TimeCardEdit', params: { id: timeCard.id } }">Input Activity</router-link>
        </div>
      </div>
    </div>

    <!-- Trainee Modal -->
    <trainee-modal :timeCardActivity="currentTimeCardActivity" :visibility="traineeModalVisibility" :isEditing="false" @onChange="close"></trainee-modal>

    <!-- Topic Modal -->
    <topic-modal :timeCardActivity="currentTimeCardActivity" :visibility="topicModalVisibility" :isEditing="false" @onChange="close"></topic-modal>

  </div>
  <loading-overlay v-else></loading-overlay>

</template>

<script>
import { TimeCardService } from '@/common/services/time_card.service';
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import TraineeModal from '@/common/components/trainee-modal.vue';
import TopicModal from '@/common/components/topic-modal.vue';

export default {
  name: 'TimeCardShow',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
    TraineeModal,
    TopicModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      timeCard: null,
      timeCardActivities: null,
      filters: FilterService.defaultData(),
      weekTotalDays: 0.0,
      currentTimeCardActivity: null,
      traineeModalVisibility: false,
      topicModalVisibility: false,
    };
  },
  created() {
    this.getTimeCard();
  },
  computed: {
    unsubmitTimeout() {
      let disabled = false;
      if (this.timeCard.submitted_date) {
        const submittedDate = new Date(this.timeCard.submitted_date);
        const today = new Date();
        const dateCutOff = new Date(new Date().setDate(today.getDate() - 7));
        if (submittedDate < dateCutOff) {
          disabled = true;
        }
      }
      return disabled;
    },
  },
  methods: {
    determineStatus() {
      switch (this.timeCard.status) {
        case 'Approved':
          return 'success';
        case 'In Progress':
          return 'primary';
        case 'Submitted':
          return 'warning';
        case 'Rejected':
          return 'danger';
        case 'Resubmitted':
          return 'warning';
        default:
          return 'primary';
      }
    },
    getTimeCard() {
      if (this.id) {
        TimeCardService.get(this.id)
          .then((response) => {
            this.timeCard = response.data;
            this.log('timeCardShow -> getTimeCard -> timeCardService.get', {
              response,
              filters: this.filters,
            });

            this.filters.s = 'created_at asc';
            this.filters.time_card_id_eq = this.timeCard.id;
            this.filters.created_eq = true;
            const params = FilterService.build({ page: 1, per_page: 50 }, this.filters);
            return TimeCardActivityService.query(params);
          })
          .then((response) => {
            this.log('timeCardShow -> getTimeCard -> TimeCardActivityService.query', {
              response,
              filters: this.filters,
            });
            this.timeCardActivities = response.data;
            this.log('timeCardShow -> getTimeCard -> TimeCardActivityService.query -> timeCardActivities', response.data);
            this.updateTotalDays(this.timeCardActivities);
          });
      }
    },
    sumDay(prop) {
      let sum = 0;

      const filteredTimeCards = this.timeCardActivities.filter(timeCardActivity => timeCardActivity[prop]);
      _.forEach(filteredTimeCards, (timeCardActivity) => {
        sum += this.convertTimeStringToValue(timeCardActivity[prop]);
      });

      return sum;
    },
    openTraineeModal(timeCardActivity) {
      this.close();
      this.currentTimeCardActivity = timeCardActivity;
      this.traineeModalVisibility = true;
      this.topicModalVisibility = false;
    },
    openTopicModal(timeCardActivity) {
      this.close();
      this.currentTimeCardActivity = timeCardActivity;
      this.topicModalVisibility = true;
      this.traineeModalVisibility = false;
    },
    close() {
      this.currentTimeCardActivity = {};
      this.topicModalVisibility = false;
      this.traineeModalVisibility = false;
    },
    unsubmitTimeCard() {
      this.timeCard.status = 'In Progress';

      TimeCardService.update(this.$route.params.id, this.timeCard)
        .then(() => {
          this.$router.push({ name: 'TimeCardShow', params: { id: this.$route.params.id } });
        });
    },
    updateTotalDays(timeCardActivities) {
      let runningTotal = 0.0;

      for (let i = 0; i < timeCardActivities.length; i += 1) {
        const days = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'];

        for (let j = 0; j < 7; j += 1) {
          const column = 'day_'.concat(days[j]);
          runningTotal += this.convertTimeStringToValue(timeCardActivities[i][column]);
        }
      }

      this.weekTotalDays = runningTotal;
    },
    printTopics(activity) {
      let topics = '';

      for (let i = 0; i < activity.topics.length; i += 1) {
        topics += activity.topics[i].title;
        if (i !== activity.topics.length - 1) {
          topics += ', ';
        }
      }

      if (topics === '') {
        topics = 'No topics';
      }

      return topics;
    },
    printTrainees(activity) {
      let trainees = '';

      for (let i = 0; i < activity.trainees.length; i += 1) {
        trainees += activity.trainees[i].first_name;
        trainees += ' ';
        trainees += activity.trainees[i].last_name;

        if (i !== activity.trainees.length - 1) {
          trainees += ', ';
        }
      }

      if (trainees === '') {
        trainees = 'No trainees';
      }

      return trainees;
    },
    printComment(activity) {
      if (activity.comment === null) { return 'No Comment'; }
      return activity.comment;
    },
  },
};

</script>

<style lang="scss" scoped>
  .rejection-notes {
    text-align: left;
  }
</style>
